@import './View.css';

.qr-container {
    text-align: center;
    margin: 20px auto;
}

.url-container {
    text-align: center;
    margin-top: 10px;
}

.url-container a {
    color: #007bff;
    text-decoration: none;
    font-size: 16px;
}

.url-container a:hover {
    text-decoration: underline;
}
  