/* General layout */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f5f7fa;
  }
  
  .submit-form {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  /* Form Header */
  .submit-form h2 {
    margin-bottom: 1.5rem;
    color: #333333;
    text-align: center;
  }

  
  .form-group {
    margin-bottom: 1rem;
    width: 94%;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #555555;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    color: #333333;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #007BFF;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
  
  /* Half-Width Inputs (for First Name & Last Name) */
  .half-width {
    flex: 1; /* Ensures both inputs are the same width */
    min-width: 0; /* Prevents overflow issues */
  }
  
  /* Submit Button */
  .submit-btn {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    color: #ffffff;
    background: #007BFF;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .submit-btn:hover {
    background: #2563a2;
  }

  /* Popup Container */
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup Box */
  .popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
    width: 85%;
    max-width: 500px;
  }
  
  /* Icon */
  .popup-icon {
    font-size: 50px;
    color: green;
  }
  
  /* Title */
  .popup h3 {
    font-size: 24px;
    margin: 10px 0;
    color: #333;
  }
  
  /* Description */
  .popup p {
    font-size: 16px;
    margin: 10px 0;
    color: #666;
  }
  
  /* Close Button */
  .close-popup-btn {
    background-color: green;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .close-popup-btn:hover {
    background-color: darkgreen;
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .submitted-data {
    margin-top: 10px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .submitted-data h4 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .submitted-table {
    border-collapse: collapse;
  }
  
  .submitted-table td {
    padding: 5px 5px;
    vertical-align: top;
  }
  
  .submitted-table td:first-child {
    text-align: right;
    color: #555;
    font-weight: bold;
    width: 30%;
  }
  
  .submitted-table td:last-child {
    text-align: left;
    color: #222;
    width: 70%;
  }
  